define("discourse/plugins/discourse-multilingual/discourse/components/content-language-discovery", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "I18n"], function (_exports, _component, _object, _service, _multilingual, _multilingualRoute, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ContentLanguageDiscovery extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get shouldRender() {
      return this.siteSettings.multilingual_enabled && this.siteSettings.multilingual_content_languages_enabled && this.siteSettings.multilingual_content_languages_topic_filtering_enabled && (this.currentUser || this.router.currentRouteName.indexOf("categories") === -1);
    }
    get contentLanguages() {
      let contentLangs = this.currentUser ? this.currentUser.content_languages : this.site.content_languages;
      if (contentLangs) {
        if (this.currentUser) {
          if (!contentLangs.some(l => l.locale === "set_content_language")) {
            contentLangs.push({
              icon: "plus",
              locale: "set_content_language",
              name: _I18n.default.t("user.content_languages.set")
            });
          }
        } else {
          contentLangs.forEach(l => {
            (0, _object.set)(l, "classNames", "guest-content-language");
          });
        }
      }
      return contentLangs;
    }
    get hasLanguages() {
      let hasLangs;
      if (this.currentUser && this.contentLanguages) {
        hasLangs = this.contentLanguages.filter(l => (0, _multilingual.isContentLanguage)(l.locale, this.siteSettings)).length > 0;
      } else {
        hasLangs = (0, _multilingualRoute.getDiscoveryParam)(this, _multilingualRoute.contentLanguageParam);
      }
      return hasLangs;
    }
  }
  _exports.default = ContentLanguageDiscovery;
});