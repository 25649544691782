define("discourse/plugins/discourse-multilingual/discourse/templates/connectors/above-footer/language-switcher-footer", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{language-switcher-bar}}
  
  */
  {
    "id": "jOGwhPk1",
    "block": "[[[41,[28,[32,0],[\"language-switcher-bar\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"language-switcher-bar\"],[[28,[32,1],[\"language-switcher-bar\"],null]]]]],[[[1,[52,[30,1,[\"language-switcher-bar\"]],[28,[30,1,[\"language-switcher-bar\"]],null,null],[28,[32,2],[[30,0],\"language-switcher-bar\",\"[\\\"The `language-switcher-bar` property path was used in the `discourse/plugins/discourse-multilingual/discourse/templates/connectors/above-footer/language-switcher-footer.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.language-switcher-bar}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"if\",\"language-switcher-bar\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/connectors/above-footer/language-switcher-footer.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});