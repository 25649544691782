define("discourse/plugins/discourse-multilingual/discourse/connectors/above-footer/language-switcher-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(attrs, ctx) {
      return ctx.siteSettings.multilingual_enabled && ctx.siteSettings.multilingual_guest_language_switcher === "footer" && !ctx.siteSettings.login_required && !ctx.currentUser;
    }
  };
});