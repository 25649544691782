define("discourse/plugins/discourse-multilingual/discourse/routes/admin-multilingual", ["exports", "@ember/routing/route", "discourse/lib/ajax", "@ember/object", "@ember/service"], function (_exports, _route, _ajax, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminMultilingualRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    beforeModel(transition) {
      if (transition.intent.url === "/admin/multilingual" || transition.intent.name === "adminMultilingual") {
        this.router.transitionTo("adminMultilingualLanguages");
      }
    }
    setupController(controller, model) {
      controller.setProperties({
        tagGroupId: model.content_language_tag_group_id,
        documentationUrl: "https://thepavilion.io/c/knowledge/discourse/multilingual"
      });
    }
    showSettings() {
      const controller = this.controllerFor("adminSiteSettings");
      this.router.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
        controller.set("filter", "multilingual");
        controller.set("_skipBounce", true);
        controller.filterContentNow("plugins");
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showSettings", [_object.action]))();
    model() {
      return (0, _ajax.ajax)("/admin/multilingual");
    }
  }
  _exports.default = AdminMultilingualRoute;
});