define("discourse/plugins/discourse-multilingual/discourse/routes/admin-multilingual-languages", ["exports", "discourse/plugins/discourse-multilingual/discourse/models/multilingual-language", "discourse/routes/discourse"], function (_exports, _multilingualLanguage, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      return _multilingualLanguage.default.list(params);
    },
    setupController(controller, model) {
      controller.set("languages", model);
      controller.setupObservers();
    }
  });
});