define("discourse/plugins/discourse-multilingual/discourse/components/admin-language-list", ["exports", "@ember/object", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _object, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "admin-language-list",
    controlColumnClassNames: ["language-control"],
    allContentEnabled: false,
    allInterfaceEnabled: false,
    updateAllContent() {
      this.languages.forEach(l => {
        (0, _object.set)(l, "content_enabled", this.allContentEnabled);
      });
    },
    updateAllInterface() {
      this.languages.forEach(l => {
        if (l.locale !== "en") {
          (0, _object.set)(l, "interface_enabled", this.allInterfaceEnabled);
        }
      });
    }
  }, [["method", "updateAllContent", [(0, _decorators.observes)("allContentEnabled")]], ["method", "updateAllInterface", [(0, _decorators.observes)("allInterfaceEnabled")]]]));
});