define("discourse/plugins/discourse-multilingual/discourse/components/language-switcher", ["exports", "@glimmer/component", "float-kit/components/d-menu", "discourse-common/helpers/i18n", "discourse/plugins/discourse-multilingual/discourse/components/language-switcher-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dMenu, _i18n, _languageSwitcherMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LanguageSwitcher extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          title={{i18n "user.locale.title"}}
          @icon="translate"
          id="multilingual-language-switcher"
          class="icon btn-flat"
        >
          <:content>
            <LanguageSwitcherMenu />
          </:content>
        </DMenu>
      
    */
    {
      "id": "T0qAgpeD",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,\"title\",[28,[32,1],[\"user.locale.title\"],null]],[24,1,\"multilingual-language-switcher\"],[24,0,\"icon btn-flat\"]],[[\"@icon\"],[\"translate\"]],[[\"content\"],[[[[1,\"\\n        \"],[8,[32,2],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-multilingual/discourse/components/language-switcher.js",
      "scope": () => [_dMenu.default, _i18n.default, _languageSwitcherMenu.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LanguageSwitcher;
});