define("discourse/plugins/discourse-multilingual/multilgingual-admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin",
    map() {
      this.route("adminMultilingual", {
        path: "/multilingual",
        resetNamespace: true
      }, function () {
        this.route("adminMultilingualLanguages", {
          path: "/languages",
          resetNamespace: true
        });
        this.route("adminMultilingualTranslations", {
          path: "/translations",
          resetNamespace: true
        });
      });
    }
  };
});