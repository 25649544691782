define("discourse/plugins/discourse-multilingual/select-kit/components/content-languages-header", ["exports", "select-kit/components/dropdown-select-box/dropdown-select-box-header", "discourse-common/utils/decorators"], function (_exports, _dropdownSelectBoxHeader, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBoxHeader.default.extend(dt7948.p({
    btnClassName(hasLanguages) {
      return `btn no-text btn-icon ${hasLanguages ? "has-languages" : ""}`;
    }
  }, [["method", "btnClassName", [(0, _decorators.default)("selectKit.options.hasLanguages")]]]));
});