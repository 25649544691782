define("discourse/plugins/discourse-multilingual/discourse/connectors/user-preferences-interface/content-language", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.multilingual_enabled && ctx.siteSettings.multilingual_content_languages_enabled && ctx.siteSettings.multilingual_content_languages_topic_filtering_enabled;
    },
    setupComponent() {
      (0, _runloop.scheduleOnce)("afterRender", () => {
        const content = ".control-group.content-languages";
        const int = ".control-group.pref-locale";
        const text = ".control-group.text-size";
        const form = ".user-preferences form";
        if ($(text).length && !$(form).children(content).length) {
          $(content).prependTo(form);
        }
        if (!$(content).next(int).length) {
          $(int).insertAfter(content);
        }
      });
    }
  };
});