define("discourse/plugins/discourse-multilingual/discourse/connectors/composer-fields/composer-language-selector", ["exports", "discourse-common/lib/get-owner", "@ember/runloop"], function (_exports, _getOwner, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function setupSelector(isFirstPost, ctx) {
    ctx.set("showSelector", isFirstPost);
    if (isFirstPost) {
      (0, _runloop.scheduleOnce)("afterRender", () => {
        $(".content-languages-selector").appendTo(".title-and-category");
      });
    }
  }
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.multilingual_enabled && ctx.siteSettings.multilingual_content_languages_enabled;
    },
    setupComponent(attrs, ctx) {
      setupSelector(attrs.model.topicFirstPost, ctx);
      const controller = (0, _getOwner.getOwner)(this).lookup("controller:composer");
      if (controller) {
        controller.addObserver("model.topicFirstPost", this, function () {
          if (this._state === "destroying") {
            return;
          }
          setupSelector(controller.get("model.topicFirstPost"), ctx);
        });
      }
    }
  };
});