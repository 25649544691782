define("discourse/plugins/discourse-multilingual/discourse/templates/components/content-language-discovery", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
  {{content-languages-dropdown
    content=this.contentLanguages
    options=(hash
      hasLanguages=this.hasLanguages
    )}}
  {{/if}}
  
  */
  {
    "id": "KSVj0Oqr",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[1,[28,[35,1],null,[[\"content\",\"options\"],[[30,0,[\"contentLanguages\"]],[28,[37,2],null,[[\"hasLanguages\"],[[30,0,[\"hasLanguages\"]]]]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"content-languages-dropdown\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/content-language-discovery.hbs",
    "isStrictMode": false
  });
});