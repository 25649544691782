define("discourse/plugins/discourse-multilingual/discourse/lib/multilingual", ["exports", "discourse/models/site"], function (_exports, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isContentLanguage = isContentLanguage;
  function isContentLanguage(locale, siteSettings) {
    if (!locale || !siteSettings.multilingual_content_languages_enabled) {
      return false;
    }
    const site = _site.default.current();
    if (!site.content_languages) {
      return false;
    }
    return site.content_languages.find(cl => cl.locale === locale);
  }
});