define("discourse/plugins/discourse-multilingual/discourse/components/multilingual-uploader", ["exports", "@ember/object/computed", "@ember/component", "discourse/mixins/uppy-upload", "@ember/object/evented", "discourse-common/utils/decorators", "I18n"], function (_exports, _computed, _component, _uppyUpload, _evented, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_uppyUpload.default, dt7948.p({
    type: "yml",
    addDisabled: (0, _computed.alias)("uploading"),
    elementId: "multilingual-uploader",
    preventDirectS3Uploads: true,
    classNameBindings: [":multilingual-uploader", "uploadType"],
    locale: null,
    message: null,
    _init: (0, _evented.on)("didInsertElement", function () {
      this.messageBus.subscribe("/uploads/" + this.type, msg => {
        if (msg.uploaded) {
          this.setProperties({
            uploading: false,
            message: _I18n.default.t("uploaded")
          });
          this.done();
        } else if (msg.errors) {
          this.set("message", msg.errors[0]);
        }
        setTimeout(() => {
          this.set("message", null);
        }, 10000);
      });
    }),
    uploadUrl(uploadType) {
      return `/admin/multilingual/${uploadType}s`;
    },
    uploadDone() {
      // wait for message that uploaded file is processed.
    },
    validateUploadedFilesOptions() {
      return {
        skipValidation: true
      };
    }
  }, [["method", "uploadUrl", [(0, _decorators.default)("uploadType")]]]));
});