define("discourse/plugins/discourse-multilingual/select-kit/components/content-languages-selector", ["exports", "select-kit/components/multi-select"], function (_exports, _multiSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _multiSelect.default.extend({
    classNames: ["content-languages-selector", "classNames"],
    allowAny: false,
    valueProperty: "locale",
    nameProperty: "name",
    options: {
      filterable: true
    },
    initializeContentLanguges: true,
    didInsertElement() {
      this._super(...arguments);
      if (!this.value && this.initializeContentLanguges && this.currentUser.content_languages.length) {
        this.set("value", this.currentUser.content_languages[0].locale);
      }
    }
  });
});